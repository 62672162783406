import React from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import showdown from 'showdown'

import Layout from '../components/Layout'
import SEO from '../components/Helpers/seo'
import Welcome from '../components/Welcome'
import Image from '../components/Helpers/image'
import { Grid, GridColumn, GridRow } from '../components/Grid'


export const query = graphql`
{
  markdownRemark(fileAbsolutePath: {regex: "/(sustainability/_index)/"}) {
    frontmatter {
      seo {
        seo_description
        seo_title
      }
      welcome {
        welcome_content
        welcome_title
        welcome_image
      }
      sustainability_block_one {
        title
        body
        image
      }
      sustainability_block_two {
        title
        body
        image
      }
    }
  }
}
`

const Sustentability = ({ data }) => {
  const SustentabilityData = data.markdownRemark.frontmatter
  const converter = new showdown.Converter()

  return(
    <Layout>
      <SEO
        title={ SustentabilityData.seo.seo_title  }
        description={ SustentabilityData.seo.seo_description }/>
      
      <Welcome
        title={ SustentabilityData.welcome.welcome_title }
        image={ SustentabilityData.welcome.welcome_image }
        content={ SustentabilityData.welcome.welcome_content } />

        <Grid>
            <GridRow>
              <GridColumn moreClass="grid-col--four">
                <Fade bottom>
                  <h3>{ SustentabilityData.sustainability_block_one.title }</h3>

                  <figure>
                    <Image filename={ SustentabilityData.sustainability_block_one.image } />
                  </figure>
                </Fade>
              </GridColumn>

              <GridColumn moreClass="grid-col--five">
                <Fade bottom>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(SustentabilityData.sustainability_block_one.body) }}></div>
                </Fade>
              </GridColumn>
            </GridRow>
        </Grid>

        <Grid>
            <GridRow>
              <GridColumn moreClass="grid-col--four">
                  <Fade bottom>
                    <h3>{ SustentabilityData.sustainability_block_two.title }</h3>

                    <figure>
                      <Image filename={ SustentabilityData.sustainability_block_two.image } />
                    </figure>
                  </Fade>
              </GridColumn>

              <GridColumn moreClass="grid-col--five">
                  <Fade bottom>
                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(SustentabilityData.sustainability_block_two.body) }}></div>
                  </Fade>
              </GridColumn>
            </GridRow>
        </Grid>
    </Layout>
  )
}


export default Sustentability

